"use strict";

var app = (function () {

  var winWidth = document.documentElement.clientWidth;
  var winHeight = document.documentElement.clientHeight;

  var _onLoad = function () {

    // плавная прокрутка к элементу
    $(document).on('click', '[data-type="scroll"]', _scroller);

    $('[data-type="order"]').on('click', function(e) {
      e.preventDefault();
      var title = $(this).attr('data-title'),
        $modalInputType = $('#modal-input-type'),
        $placeholder = $('#modal-placeholder');

      $placeholder.text(title);
      $modalInputType.val('Рассчитать стоимость '+title);

      $.fancybox.open({
        src  : '#order',
        type : 'inline',
      });
    });

    $('input[type="tel"]').mask('+7 (999) 999-99-99'); // телефон

    if ( document.querySelector('.form-validation') ) {
      // валидация форм
      $.validate({
        form: '.form-validation',
        lang: 'ru',
        scrollToTopOnError: false,
        borderColorOnError: '#b71c1c'
      });

      $('form').on('submit', _sendForm);
    }

    $('[data-fancybox]').fancybox({ buttons : [ 'close' ] });

    if ( document.getElementById('headroom') ) {
      var headroom = new Headroom( document.getElementById('headroom') );
      headroom.init();
    }

    if ( document.getElementById('sl-works') ) {
      $('#sl-works').slick({
        centerMode: true,
        centerPadding: '25%',
        dots: true,
        nextArrow: '<span class="slick-arrow next slick-next"></span>',
        prevArrow: '<span class="slick-arrow prev slick-prev"></span>',
        touchThreshold: 10000
      });
    }

    if ( document.getElementById('sl-clients') ) {
      $('#sl-clients').slick({
        slidesToShow: 5,
        touchThreshold: 10000,
        nextArrow: '<span class="slick-arrow next slick-next"></span>',
        prevArrow: '<span class="slick-arrow prev slick-prev"></span>',
        responsive: [{
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          }
        },{
          breakpoint: 544,
          settings: {
            slidesToShow: 2,
          }
        }]
      });
    }

    if ( document.getElementById('map') ) {
      _createMap( document.getElementById('map') );
    }

  }; // end onLoad

  var _createMap = function (elem) {
    // Яндекс Карта
    // http://dimik.github.io/ymaps/examples/location-tool/
    // script(src="https://api-maps.yandex.ru/2.1/?lang=ru_RU" type="text/javascript")
    // #elem(data-center="" data-mark="" data-address="" data-zoom="" data-image="" data-image-size="")

    var mapCenter = elem.getAttribute('data-center'), // центр карты
      mapMark = elem.getAttribute('data-mark'), // метка
      mapAddress = elem.getAttribute('data-address'), // всплывашка
      mapZoom = elem.getAttribute('data-zoom'), // зум
      mapImage = elem.getAttribute('data-image'), // адрес картинки

      mapCenterCoords = mapCenter.split(','),
      mapMarkCoords = mapMark.split(','),

      myMap,
      myPlacemark;

    ymaps.ready(init);

    function init(){
      myMap = new ymaps.Map(elem, {
        center: mapCenterCoords,
        zoom: mapZoom,
        // controls: ['geolocationControl', 'routeButtonControl', 'typeSelector', 'fullscreenControl', 'zoomControl']
        controls: ['zoomControl','fullscreenControl']
      });

      myMap.behaviors.disable('scrollZoom');

      if ( mapImage ) {

        var mapImageSize = elem.getAttribute('data-image-size'), // ширина, высота
          mapMarkSize = mapImageSize.split(',');

        myPlacemark = new ymaps.Placemark(mapMarkCoords, { hintContent: mapAddress}, {
          iconLayout: 'default#image',
          iconImageHref: mapImage,
          iconImageSize: mapMarkSize,
          iconImageOffset: [-(mapMarkSize[0]/2), -mapMarkSize[1]]
        });

      } else {

        myPlacemark = new ymaps.Placemark(mapMarkCoords, { hintContent: mapAddress});

      }

      myMap.geoObjects.add(myPlacemark);
    }
  }; // end _createMap


  var _sendForm = function ( e ) {
    e.preventDefault();

    var form = e.target,
      formData = $(form).serialize(),
      btn = form.querySelector('button[type="submit"]'),
      url = 'php/form-handler.php';

    btn.setAttribute('disabled','disabled');

    $.post(url, formData, function (res) {

      var result = $.parseJSON(res);

      if ( result.success ) {
        $(form).get(0).reset();
        btn.removeAttribute('disabled');

        window.location = 'https://szdesign.ru/thanks.html';

        btn.removeAttribute('disabled');

      } else {
        console.log(res);
      }
    });

  };

  var _scroller = function(e) {
    e.preventDefault();
    var offset = 100;
    if ( winWidth < 768 ) {
      offset = 90;
    }

    var scroll_el = $(this).attr('href');
    if ( $(scroll_el).length ) {
        $('html, body').animate({ scrollTop: $(scroll_el).offset().top - offset }, 500);
    }

  };


  var init = function () {
    svg4everybody();
    document.addEventListener('DOMContentLoaded', _onLoad);
  };

  return { init: init };
})();
app.init();